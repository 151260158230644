import React from "react";
import "./treatments-list.scss";
import { StaticImage } from "gatsby-plugin-image";
import XrayIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/צילום-רנטגן-של-שן.svg";
import MeetingIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-פגישת-ייעוץ.svg";
import ScalingIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-שיננית.svg";
import FillingIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-סתימה.svg";
import WhiteningIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-הלבנת-שיניים.svg";
import CrownIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-כתרים-וגשרים.svg";
import NightGaurdIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/סד-לילה.svg";
import BotoxIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/בוטוקס-וחומצה-הילארונית.svg";
import DenturesIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/שיניים-תותבות.svg";
import RootCanalIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/טיפול-שורש.svg";
import ImplantsIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/שתלים.svg";
import ExtractionIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/עקירת-שיניים.svg";
import EmergencyIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/עזרה-ראשונה.svg";
import LaminateIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/ציפויים.svg";

const TreatmentList = () => {
  return (
    <div className="contentContainer">
      <div>
        <MeetingIcon className="xrayIcon" />
        <XrayIcon className="xrayIcon" />
      </div>
      <h2 className={"treatmentListHeading"}>
        אוריה, תקבעי לי פגישת אבחון וייעוץ
        <br /> שני צילומים - חינם!
      </h2>
      <div className={"treatmentListContainer"}>
        <ul>
          <li>
            <ScalingIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" itemProp="name" className="treatmentTitle">
                שיננית
              </h3>
              <h4>רפואה מונעת והיגיינת הפה</h4>
            </div>
          </li>
          <li>
            <FillingIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                סתימות
              </h3>
              <h4>רפואה משמרת</h4>
            </div>
          </li>

          <li>
            <WhiteningIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                אסתטיקה והלבנת שיניים
              </h3>
            </div>
          </li>
          <li>
            <CrownIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                כתרים וגשרים
              </h3>
              <h4>שיקום הפה</h4>
            </div>
          </li>
          <li>
            <RootCanalIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                טיפול שורש
              </h3>
            </div>
          </li>
          <li>
            <ExtractionIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                עקירות שיניים
              </h3>
            </div>
          </li>
          <li>
            <ImplantsIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                שתלים
              </h3>
            </div>
          </li>
          <li>
            <DenturesIcon alt="" />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                שיניים תותבות
              </h3>
              <h4>נשלפות וקבועות</h4>
            </div>
          </li>
          <li>
            <EmergencyIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                עזרה ראשונה
              </h3>
            </div>
          </li>
          <li>
            <BotoxIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                בוטוקס וחומצה הילארונית
              </h3>
            </div>
          </li>
          <li>
            <LaminateIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                ציפויי חרסינה
              </h3>
              <h4>Laminate</h4>
            </div>
          </li>
          <li>
            <NightGaurdIcon />
            <div
              itemScope
              itemType="http://schema.org/MedicalProcedure"
              className={"treatmentLabels"}
            >
              <h3 itemProp="name" className="treatmentTitle">
                סד לילה / נחירות
              </h3>
              <h4>למניעת נחירות ושחיקת שיניים</h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TreatmentList;
