import React from "react";
import { Helmet } from "react-helmet";

type SeoProps = {
  title: string;
  description: string;
};

const Seo = ({ title, description }: SeoProps) => {
  const metaTitle: string = title; //|| site.siteMetadata.defaultTitle;
  const metaDescription = description; //|| site.siteMetadata.defaultDescription;

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Dentist",
    name: "[מרפאת שיניים בירושלים] ד״ר אוריה פינטו",
    description: "מרפאת שיניים בירושלים",
    slogan: "מחוייבת להשאיר אתכם מחייכים",
    openingHours: "Su-Th 9:00-19:00",
    telephone: "02-6646747",
    email: "oria@whitesmile.co.il",
    address: {
      "@type": "PostalAddress",
      addressLocality: "ירושלים",
      addressRegion: "ישראל",
      postalCode: "9459201",
      streetAddress: "שמואל הנגיד 1"
    },
    priceRange: "$$",
    image:
      "https://s3.eu-central-1.amazonaws.com/whitesmile-static/profile/רופאת-שיניים-בירושלים.png"
  };

  return (
    <Helmet
      htmlAttributes={{ lang: "he-IL" }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: "dir",
          content: "rtl"
        }
      ]}
    >
      <script type="application/ld+json">{`${JSON.stringify(ldJson)}`}</script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-680666253"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'AW-680666253');`}
      </script>
      <script>
        {`
          function gtag_report_conversion(url) {
           if(window.location.hostname !== "www.whitesmile.co.il"){
              return
            }
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-680666253/_rE0COum57gBEI3JyMQC',
                'event_callback': callback
            });
            return false;
          }
        `}
      </script>
    </Helmet>
  );
};

export default Seo;
