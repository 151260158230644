import React, { useState } from "react";
import "./leave-details.scss";
import PlaneIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-להשאיר-פרטים-למרפאת-השיניים-בירושלים.svg";
import { StaticImage } from "gatsby-plugin-image";

const nameRegex = /^[א-תA-z׳ ]{2,25}$/g;
const phoneRegex = /^[\d]{9,10}$/g;

export const useInput = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event: { target: { value: any } }) => {
        setValue(event.target.value);
      }
    }
  };
};

const LeaveDetails = () => {
  const { value: name, bind: bindName, reset: resetName } = useInput("");
  const { value: phone, bind: bindPhone, reset: resetPhone } = useInput("");
  const [validInput, setValidInput] = useState(true);
  const [inProgress, setInProgress] = useState(false);

  const activateErrorToggle = () => {
    setValidInput(false);
    setTimeout(() => setValidInput(true), 1000);
  };

  const callApi = () => {
    const axios = require("axios").default;
    axios
      .post(
        "https://srqgfrx44ors5flenaef5cm7jy0zqxgk.lambda-url.eu-central-1.on.aws",
        {
          name: name,
          phone: phone
        }
      )
      .then(function(response: any) {
        // @ts-ignore
        gtag_report_conversion();
        resetName();
        resetPhone();
        window.location.href = "הצלחה";
      })
      .catch(function(error: any) {
        activateErrorToggle();
        return;
      });
  };

  const handleSubmit = (evt: { preventDefault: () => void }) => {
    evt.preventDefault();
    setInProgress(true);

    // Validate
    if (!(name.match(nameRegex) && phone.match(phoneRegex))) {
      activateErrorToggle();
      setInProgress(false);
      return;
    }

    callApi();

    setInProgress(false);
  };

  const validInputClass = validInput ? "" : "failed";

  return (
    <div className="leaveDetailsContainer" id="leaveDetails">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          {...bindName}
          placeholder="שם מלא"
          className={validInputClass}
          disabled={inProgress}
        />
        <input
          type="tel"
          {...bindPhone}
          placeholder="טלפון"
          className={validInputClass}
          disabled={inProgress}
        />
        <button type="submit" disabled={inProgress} className="submitButton">
          אוריה, תחזרי אליי <PlaneIcon className="footerPlaneIcon" />
        </button>
      </form>
      <div className="locationAndContanctsContainer">
        <StaticImage
          className="locationIcon"
          src="../images/אייקון-מיקום-של-כתובת-המרפאה.png"
          alt="ד״ר אוריה פינטו | רופאת שיניים"
          placeholder="blurred"
          loading={"lazy"}
          height={50}
          width={50}
          transformOptions={{ fit: "contain" }}
        />
        <div>שמואל הנגיד 1, ירושלים</div>
      </div>
      <div className="locationAndContanctsContainer">
        <StaticImage
          className="callIcon"
          src="../images/להתקשר-24-7.png"
          alt="ד״ר אוריה פינטו | רופאת שיניים"
          placeholder="blurred"
          loading={"lazy"}
          height={50}
          width={50}
          transformOptions={{ fit: "contain" }}
        />
        <div>02-6646747</div>
      </div>
    </div>
  );
};

export default LeaveDetails;
