import React from "react";
import "./footer.scss";
import { FaWhatsapp } from "react-icons/fa";
import { useEffect } from "react";
import { Link } from "gatsby";
import LeaveDetails from "./LeaveDetails";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  useEffect(() => {
    const gmap = document.getElementById("gmap_canvas");
    if (gmap !== null && gmap.ontouchstart !== null) {
      gmap.addEventListener("touchstart", gmap.ontouchstart, { passive: true });
    }
  }, []);

  return (
    <footer>
      <div className="footerContainer">
        <div className="mapAndFormConainer">
          <LeaveDetails />
          <a href="https://g.page/oriapinto?share">
            <StaticImage
              src="../images/מפת-גוגל-מרפאת-שיניים-ירושלים.png"
              alt="ד״ר אוריה פינטו | רופאת שיניים"
              placeholder="blurred"
              loading={"lazy"}
              transformOptions={{ fit: "inside" }}
              className={"map"}
            />
          </a>
        </div>

        <a href="https://wa.me/972533626114">
          <FaWhatsapp className="whatsapp" title="צור קשר דרך whatsapp" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
