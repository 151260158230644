import * as React from "react";
import Banner from "../components/Banner";
import * as styles from "./landing-page-content.module.scss";
import TreatmentList from "./TreatmentsList";

const LandingPageContent = () => {
  return (
    <div>
      <Banner />
      <TreatmentList />
    </div>
  );
};

export default LandingPageContent;
