import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import LandingPageContent from "../components/LandingPageContent";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title={"רופא שיניים בירושלים | מרפאת שיניים בירושלים | ד״ר אוריה פינטו"}
        description={
          "רופאת שיניים בירושלים | ד״ר אוריה פינטו. מובילה את רשימת רופאי השיניים המומלצים בירושלים באתר מידרג. בוגרת האונברסיטה העברית בירושלים, הדסה עין כרם. ללא פשרות על איכות ובמחיר הוגן. השאירו פרטים ואחזור אליכם בהקדם"
        }
      />
      <LandingPageContent />
    </Layout>
  );
};

export default IndexPage;
