import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./banner.module.scss";
import PlaneIcon from "-!svg-react-loader?props[]=className:w-4 h-4!../images/svg/אייקון-של-להשאיר-פרטים-למרפאת-השיניים-בירושלים.svg";
import ClinicVideo from "../assets/clinic.mp4";

const VideoOrImage = () => {
  const [isVideoSupported, setIsVideoSupported] = useState(true);
  const [speedGoodEnough, setSpeedGoodEnough] = useState(false);

  useEffect(() => {
    // Check if the browser supports video playback
    if (!document.createElement("video").canPlayType) {
      setIsVideoSupported(false);
    }

    // Check the connection speed - only supported in chrome
    if (window.chrome) {
      if (navigator.connection && !!navigator.connection.effectiveType) {
        if (navigator.connection.effectiveType === "4g") {
          setSpeedGoodEnough(true);
        }
      }
    } else {
      setSpeedGoodEnough(true);
    }
  }, []);

  // If the browser supports video playback and the connection speed is sufficient, display the video
  if (isVideoSupported && speedGoodEnough) {
    return (
      <video
        playsInline
        autoPlay
        muted
        loop
        className={styles.bannerBackground}
      >
        <source src={ClinicVideo} type="video/mp4" />
      </video>
    );
  }
  // Otherwise, display the image
  return <div className={styles.bannerBackground} />;
};

const Banner = () => {
  return (
    <div className={styles.bannerContainer}>
      <VideoOrImage />
      <div className={styles.bannerContentContainer}>
        <div>
          <h1 className={styles.drTitle}>מחוייבת להשאיר אתכם מחייכים!</h1>
          <Link
            to="/#leaveDetails"
            className={styles.leaveDetailsLink}
            activeClassName={styles.leaveDetailsLink}
          >
            <div className={styles.callMeBackText}>אוריה, תחזרי אליי</div>
            <PlaneIcon className="planeIcon" />
          </Link>
        </div>
        <StaticImage
          src="../images/רופאת-שיניים-בירושלים-דר-אוריה-פינטו.png"
          alt="רופא שיניים בירושלים | ד״ר אוריה פינטו | רופאת שיניים בירושלים | ד״ר פינטו"
          placeholder="blurred"
          loading={"eager"}
          height={500}
          width={450}
          transformOptions={{ fit: "inside" }}
          className={styles.drOriaImage}
        />
      </div>
    </div>
  );
};

export default Banner;
