import React from "react";

import Header from "./Header";
import Footer from "./Footer";

// Styles
import "../styles/reset.css";
import "../styles/accessibility.css";
import "../styles/global.module.css";
import "./layout.scss";

type LayoutProps = {
  children?: JSX.Element | JSX.Element[];
};
const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <a className="skip-link screen-reader-text" href="#primary">
        Skip to the content
      </a>
      <Header title={"ד״ר אוריה"} description={"רופאת שיניים מדהימה"} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
